import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-1.css';

const PageOne = forwardRef((props, ref) => (
  <>
    <SEO title="Page 1" />
    <main className="page page-reverse page1">
      <div className="page__image-container">
        <div className="page__image-border-container">
          <StaticImage
            alt="Amanda, standing in front of clothes dryer"
            aspectRatio={5600 / 5000}
            className="page__image"
            height={750}
            layout="constrained"
            loading="eager"
            objectFit="contain"
            placeholder="tracedSVG"
            src="../../images/page-1.png"
          />
        </div>
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            Amanda was staring at the clothes dryer and dreading the moment she would have to
            open the door and start folding the laundry.
          </p>
          <p>
            It wasn&apos;t hard, except that there was always at least one sock missing when she tried
            to match them up. Even more puzzling was that a missing sock sometimes showed up
            in the next wash.
            {' '}
          </p>
          <p>
            <strong>Where did those socks go?</strong>
          </p>
        </div>
      </div>
      <PreviousPage to="/" />
      <NextPage to="/page-2" />
    </main>
  </>
));

const PageOneContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageOne ref={textRef} />;
};

export default PageOneContainer;
